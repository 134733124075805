.header {
  background-color: rgba(51, 65, 91, 0.96);
  box-shadow: 0px 12px 24px rgba(26, 39, 64, 0.125);
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .header {
    justify-content: stretch;
  }
}
