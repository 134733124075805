.icon {
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon svg {
  fill: #bec3cd;
}

.icon svg:hover {
  fill: white;
}
