body {
  margin: 0;
  height: 100vh;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  letter-spacing: 0.05em;
  background-color: #33415b;
}
