.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4em;
  background-color: #33415b;
  color: white;
  padding-inline: 2em;
  margin-top: auto;
}

.message {
  text-align: center;
  width: 100%;
  max-width: 30em;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 2.625em;
}

.link {
  font-weight: 900;
  text-decoration: none;
  color: inherit;
}

.imageWrapper {
  width: 100%;
  max-width: 32em;
}

.imageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (min-width: 768px) {
  .content {
    flex-direction: row;
  }

  .message {
    text-align: start;
  }
}
